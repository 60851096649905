import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import YotpoStars from 'src/components/YotpoStars'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  Container,
  TextBox,
  Text,
  CenteredOnMobile,
  Section,
  VideoContainer,
  HeroVideo,
  SubHeader,
  BuyNowLink,
  Item,
  ListItem,
  CheckMark,
  BuyNowAnchor,
  ExternalLink,
  Subheading,
} from './styles'
import {
  BUY_LINK,
  IN_AU,
  IN_CA,
  IN_GERMANY,
  IN_IE,
  IN_UK,
  LOCALIZATION,
} from '../../utils/constants'
import heroVideo from '../../videos/frame-video.mp4'
import greetingVideo from '../../videos/greetings.mp4'
import Analytics from '../../utils/Analytics'
import { Intro } from '../CalMaxHero/styles'

const Hero = ({ page, title, subheading, ctaText }) => {
  const { t } = useTranslation('common')

  const { link, headings } = useStaticQuery(graphql`
    query HeroQuery {
      check: file(relativePath: { eq: "checkmark.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      link: file(relativePath: { eq: "external-link.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      headings: allContentfulHomepageHero {
        edges {
          node {
            en_heading
            en_ca_heading
            en_au_heading
            en_gb_heading
            en_eu_heading
            es_heading
            fr_heading
            nl_heading
            it_heading
            ie_heading
            de_heading
          }
        }
      }
    }
  `)

  const HERO_VIDEO_OVERRIDE = {
    greetings: greetingVideo,
  }

  const getAmazonLink = () => {
    if (IN_UK) return 'https://urlgeni.us/amazon/shop-amz-uk'
    if (IN_CA) return 'https://urlgeni.us/amazon/shop-amz-ca'
    if (IN_GERMANY) return 'https://urlgeni.us/amazon/shop-amz-de'
    if (IN_AU)
      return 'https://www.amazon.com.au/Skylight-Frame-Digital-Picture-Anywhere/dp/B01N7ENHO6'

    return 'https://urlgeni.us/amazon/shoptraffic'
  }

  const AMAZON_LINK = getAmazonLink()

  const videoSrc = HERO_VIDEO_OVERRIDE[page] || heroVideo
  const [videoControls, setVideoControls] = useState(false)

  useEffect(() => {
    if (
      window.navigator.userAgent.indexOf('Mozilla') !== -1 &&
      window.navigator.userAgent.indexOf('Android') !== -1
    ) {
      setVideoControls(true)
    }
  }, [])

  const itemList = [
    t('No app or subscription required'),
    t('Quick 1-minute setup — easy for all ages'),
    t('Effortless touchscreen display'),
  ]

  const headingsData = headings.edges[0].node
  const country = `${LOCALIZATION.replace(/-/g, '_')}_heading`
  const heading = headingsData?.[country]

  const subheadingText = IN_IE
    ? 'Celebrate the season with Skylight – the perfect gift at a perfect price, with special holiday savings on Frames!'
    : t(
        'Bring your family together with the Skylight digital picture Frame. The perfect gift this holiday season.'
      )

  return (
    <Section>
      <Container>
        <TextBox>
          <YotpoStars textcolor="#444444" linkUrl={BUY_LINK} />
          <Intro>{t('Buy the #1 Gift of the Season')}</Intro>
          <Text>{title || heading}</Text>
          {page === 'greetings' ? (
            <ListItem>
              Preload your loved one’s frame with photos, video and for the first time, e-cards.
            </ListItem>
          ) : (
            <>
              <Subheading>{subheadingText}</Subheading>
              {/* {itemList.map((item, i) => (
                <Item key={i}>
                  <CheckMark image={check.childImageSharp.gatsbyImageData} alt="check mark" />
                  <ListItem>{item}</ListItem>
                </Item>
              ))} */}
            </>
          )}
          {subheading && <SubHeader>{subheading}</SubHeader>}
          {page === 'amazon' ? (
            <>
              <CenteredOnMobile>
                <BuyNowAnchor
                  href={AMAZON_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => Analytics.track('Clicked to Amazon')}
                >
                  {ctaText || t('Buy Now')}{' '}
                  <ExternalLink image={link.childImageSharp.gatsbyImageData} alt="external link" />
                </BuyNowAnchor>
              </CenteredOnMobile>
              <CenteredOnMobile>
                <BuyNowLink
                  to={BUY_LINK}
                  onClick={() => Analytics.track('Clicked Buy Now on Frame Amazon Landing Page')}
                >
                  Buy Now
                </BuyNowLink>
              </CenteredOnMobile>
            </>
          ) : (
            <CenteredOnMobile>
              <BuyNowLink to={BUY_LINK}>{ctaText || t('Buy Now')}</BuyNowLink>
            </CenteredOnMobile>
          )}
        </TextBox>
        <VideoContainer>
          <HeroVideo playsInline autoPlay muted loop controls={videoControls}>
            <source src={videoSrc} type="video/mp4" />
          </HeroVideo>
        </VideoContainer>
      </Container>
    </Section>
  )
}

Hero.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  subheading: PropTypes.string,
  ctaText: PropTypes.string,
}
export default Hero
